import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useLocation } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingTop: '10px'
  }
}));

const ListMenu = props => {

  const classes = useStyles();
  const location = useLocation();

  const handleLogout = () => {

    localStorage.clear()
    if(window.location.hostname == 'localhost'){
      window.location.href = 'http://localhost:3000';
    }else{
      window.location.href = 'https://appandwebstudio.com.br';
    }

  }

  return (
    <div className={classes.root}>
      <List style={{width: '100%'}}>
        <Link onClick={() => {props.onClick()}} to="/" style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}>
          <ListItem button>
            <Tooltip title="Dashboard" disableHoverListener={true}>
              <ListItemIcon style={{ minWidth: '36px', paddingLeft: '20px' }}>
                <DashboardRoundedIcon style={{color: location.pathname === '/dashboard' ? '#FFDE5A' : 'gray'}} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Dashboard" style={{color: location.pathname === '/dashboard' ? '#FFDE5A' : 'gray'}} />
          </ListItem>
        </Link>
        <Link onClick={() => {props.onClick()}}  to="/financial" style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}>
          <ListItem button>
            <Tooltip title="Financeiro" disableHoverListener={true}>
              <ListItemIcon style={{ minWidth: '36px', paddingLeft: '20px' }}>
                <AttachMoneyRoundedIcon style={{color: location.pathname === '/financial' ? '#FFDE5A' : 'gray'}} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Financeiro" style={{color: location.pathname === '/financial' ? '#FFDE5A' : 'gray'}} />
          </ListItem>
        </Link>
        <Link onClick={() => {props.onClick()}}  to="/projects" style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}>
          <ListItem button>
            <Tooltip title="Projetos" disableHoverListener={true}>
              <ListItemIcon style={{ minWidth: '36px', paddingLeft: '20px' }}>
                <BusinessRoundedIcon style={{color: location.pathname === '/projects' ? '#FFDE5A' : 'gray'}} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Projetos" style={{color: location.pathname === '/projects' ? '#FFDE5A' : 'gray'}} />
          </ListItem>
        </Link>
        <Link onClick={() => {props.onClick()}}  to="/documents" style={{textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)'}}>
          <ListItem button>
            <Tooltip title="Documentos" disableHoverListener={true}>
              <ListItemIcon style={{ minWidth: '36px', paddingLeft: '20px' }}>
                <InsertDriveFileOutlinedIcon style={{color: location.pathname === '/documents' ? '#FFDE5A' : 'gray'}} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Documentos" style={{color: location.pathname === '/documents' ? '#FFDE5A' : 'gray'}} />
          </ListItem>
        </Link>
        <ListItem onClick={handleLogout} button>
          <Tooltip title="Sair" disableHoverListener={true}>
            <ListItemIcon style={{ minWidth: '36px', paddingLeft: '20px' }}>
              <ExitToAppIcon style={{color: 'gray'}} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Sair" style={{color: 'gray'}} />
        </ListItem>
      </List>
    </div>
  );
}

export default ListMenu;