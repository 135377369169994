import React, { useEffect, useState }  from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { renderRoutes } from 'react-router-config';
import routes from './routes';
import API from './api';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
 
const history = createBrowserHistory();

const useStyles = makeStyles((theme) => ({
    progress: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    }
}))

function App () {

    const classes = useStyles();
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {

        /** Verify logged user */

        const urlTokenParam = window.location.href.split('?')[1]

        let tokenMe = "";

        if (urlTokenParam && urlTokenParam.split('&')[0].split('=')[1]) {
            //localStorage.clear()
            tokenMe = urlTokenParam.split('&')[0].split('=')[1];
            localStorage.setItem('backofficeToken', tokenMe)
        } else {
            tokenMe = localStorage.getItem('backofficeToken');
        }

        API.defaults.headers.common = {
            'Authorization': `Bearer ${tokenMe}`,
            'Content-Type': 'application/json'
        }
       
        API.get('users/profile')
        .then(res => {
            setIsValid(true)
        })
        .catch(err => {
            if(window.location.hostname == 'localhost'){
                window.location.href = 'http://localhost:3000';
            }else{
                window.location.href = 'https://appandwebstudio.com.br';
            }
        })

    }, [0])

    return (
        <>
        
            {!isValid ? 
                <>
                    <div className={classes.progress}>
                        <CircularProgress />
                    </div>
                </>
            :
                <Router history={history}>
                    {renderRoutes(routes)}
                </Router>
            }

        </>
    );
};

export default App;