import axios from 'axios';

const location = window.location;

let url = "http://api.appandwebstudio.com.br/";

if(location.hostname == 'localhost'){
  url = 'http://localhost:8000/';
}else{
  url = 'https://api.appandwebstudio.com.br/';
}

export default axios.create({
  baseURL: url
});